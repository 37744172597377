import React, { Component, createContext } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { getWindowDimensions } from "~utils/dom";

export const AppContext = createContext({});

class AppProvider extends Component {
  state = {
    cursorCenterDeltaX: 0, // 0 at center, -0.5/0.5 at edges
    cursorCenterDeltaY: 0, // 0 at center, -0.5/0.5 at edges
    cursorPositionX: 0,
    cursorPositionY: 0,
    device: ``,
    documentHeight: 0,
    scrollTop: 0,
    windowWidth: 0,
    windowHeight: 0,

    //

    cart: [],
    cartActive: false,
    newsletterActive: false,
    PIXI: null,
    saleActive: false,
    saleUrl: null,
    simpleModalActive: false,
    simpleModalText: null,
    ticketsActive: false,
    ticketURLs: []
  };

  mobileWidth = 768;

  tabletWidth = 1024;

  //
  // React lifecycle

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log(`%c 👂 My 👀 `, `background: #106BB0; color: #F28525`);

    if (document) {
      document.addEventListener(
        `mousemove`,
        _.throttle(this.mouseMoveHandler),
        false
      );
      document.addEventListener(
        `scroll`,
        _.throttle(this.scrollHandler),
        false
      );
    }

    if (window) {
      window.addEventListener(`resize`, _.throttle(this.resizeHandler), false);
    }

    setTimeout(() => {
      this.resizeHandler();
    });
  }

  //

  mouseMoveHandler = event => {
    this.setState(prevState => ({
      cursorCenterDeltaX: -(0.5 - event.pageX / prevState.windowWidth),
      cursorPositionX: event.pageX,
      cursorCenterDeltaY: -(
        0.5 -
        (event.pageY - window.pageYOffset) / prevState.windowHeight
      ),
      cursorPositionY: event.pageY - window.pageYOffset
    }));
  };

  scrollHandler = e => {
    this.setState({
      scrollTop: e.target.scrollingElement.scrollTop
    });
  };

  resizeHandler = () => {
    let device = `desktop`;

    if (
      window.matchMedia(
        `(min-width: ${this.mobileWidth}px) and (max-width: ${this.tabletWidth}px)`
      ).matches
    ) {
      device = `tablet`;
    } else if (
      window.matchMedia(`(max-width: ${this.mobileWidth - 1}px)`).matches
    ) {
      device = `mobile`;
    }

    this.setState({
      device,
      documentHeight: document.documentElement.offsetHeight,
      windowWidth: getWindowDimensions().width,
      windowHeight: getWindowDimensions().height
    });
  };

  //
  // API

  setCart = cart => {
    this.setState({
      cart
    });
  };

  setCartActive = cartActive => {
    this.setState({
      cartActive
    });
  };

  setMenuActive = menuActive => {
    this.setState({
      menuActive
    });
  };

  setNewsletterActive = newsletterActive => {
    this.setState({
      newsletterActive
    });
  };

  setPIXI = PIXI => {
    this.setState({
      PIXI
    });
  };

  setSaleActive = saleActive => {
    this.setState({
      saleActive
    });
  };

  setSaleUrl = saleUrl => {
    this.setState({
      saleUrl
    });
  };

  setSimpleModalActive = simpleModalActive => {
    this.setState({
      simpleModalActive
    });
  };

  setSimpleModalText = simpleModalText => {
    this.setState({
      simpleModalText
    });
  };
  
  setTicketsActive = ticketsActive => {
    this.setState({
      ticketsActive
    });
  };
  
  setTicketURLs = ticketURLs => {
    this.setState({
      ticketURLs
    });
  };

  //
  // render/wrapper

  render() {
    return (
      <AppContext.Provider
        value={{
          cursorCenterDeltaX: this.state.cursorCenterDeltaX,
          cursorCenterDeltaY: this.state.cursorCenterDeltaY,
          cursorPositionX: this.state.cursorPositionX,
          cursorPositionY: this.state.cursorPositionY,
          device: this.state.device,
          documentHeight: this.state.documentHeight,
          scrollTop: this.state.scrollTop,
          windowWidth: this.state.windowWidth,
          windowHeight: this.state.windowHeight,
          //
          cart: this.state.cart,
          cartActive: this.state.cartActive,
          menuActive: this.state.menuActive,
          newsletterActive: this.state.newsletterActive,
          PIXI: this.state.PIXI,
          saleActive: this.state.saleActive,
          saleUrl: this.state.saleUrl,
          simpleModalActive: this.state.simpleModalActive,
          simpleModalText: this.state.simpleModalText,
          ticketsActive: this.state.ticketsActive,
          ticketURLs: this.state.ticketURLs,
          //
          setCart: this.setCart,
          setCartActive: this.setCartActive,
          setMenuActive: this.setMenuActive,
          setNewsletterActive: this.setNewsletterActive,
          setPIXI: this.setPIXI,
          setSaleActive: this.setSaleActive,
          setSaleUrl: this.setSaleUrl,
          setSimpleModalActive: this.setSimpleModalActive,
          setSimpleModalText: this.setSimpleModalText,
          setTicketsActive: this.setTicketsActive,
          setTicketURLs: this.setTicketURLs
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
