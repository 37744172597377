import React from "react";
import PropTypes from "prop-types";

const Cross = props => (
  <svg className={props.className} viewBox="0 0 59 59" fill="none">
    <path
      d="M55.682 10.182L58.864 7L52.5 0.636039L49.318 3.81802L55.682 10.182ZM3.81802 49.318L0.636039 52.5L7 58.864L10.182 55.682L3.81802 49.318ZM49.318 55.682L52.5 58.864L58.864 52.5L55.682 49.318L49.318 55.682ZM10.182 3.81802L7 0.636039L0.636039 7L3.81802 10.182L10.182 3.81802ZM49.318 3.81802L3.81802 49.318L10.182 55.682L55.682 10.182L49.318 3.81802ZM55.682 49.318L10.182 3.81802L3.81802 10.182L49.318 55.682L55.682 49.318Z"
      fill="black"
    />
  </svg>
);

export default Cross;

Cross.defaultProps = {
  className: ``
};

Cross.propTypes = {
  className: PropTypes.string
};
