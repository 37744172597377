/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { Link } from "gatsby";
import { AppContext } from "~context/AppContext";
import Cross from "~components/Cross";

class NavComponent extends Component {
  componentDidMount() {
    this.addKeyupListeners();
    this.addScrollListeners();
  }

  //

  addKeyupListeners = () => {
    if (!window) {
      return;
    }

    window.addEventListener(`keyup`, e => {
      switch (e.keyCode) {
        case 27:
          this.close();

          break;

        default:
          break;
      }
    });
  };

  addScrollListeners = () => {
    if (!document) {
      return;
    }

    document.addEventListener(`scroll`, () => {
      this.close();
    });
  };

  //

  close = () => {
    this.props.appContext.setMenuActive(false);
  };

  //

  render() {
    const { appContext } = this.props;

    //

    return (
      <div
        className={`nav ${
          this.props.appContext.menuActive ? `menu-active` : ``
        } w-screen h-screen fixed flex items-center justify-center z-50`}
      >
        <div
          role="presentation"
          className="nav__background w-screen h-screen fixed top-0 right-0 bottom-0 left-0 bg-black z-10"
          onClick={() => this.props.appContext.setMenuActive(false)}
        ></div>

        <div className="nav__menu h-full absolute top-0 right-0 py-16 px-8 z-20 bg-white">
          <button
            type="button"
            className="nav__close absolute top-0 right-0 z-30"
            onClick={() => appContext.setMenuActive(false)}
          >
            <Cross className="w-full relative block" />
          </button>

          <ul className="relative">
            {appContext.saleActive && appContext.saleUrl && (
              <li className="nav__menu__link f4 text-black">
                <a
                  href={appContext.saleUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  tickets
                </a>
              </li>
            )}

            <li className="nav__menu__link f4 text-black">
              <Link
                to="/"
                onClick={() => this.props.appContext.setMenuActive(false)}
              >
                events
              </Link>
            </li>

            {/* <li className="nav__menu__link f4 text-black">
              <Link
                to="/"
                onClick={() => this.props.appContext.setMenuActive(false)}
              >
                on sale
              </Link>
            </li> */}

            <li className="nav__menu__link f4 text-black">
              <Link
                to="/past-events"
                onClick={() => this.props.appContext.setMenuActive(false)}
              >
                past events
              </Link>
            </li>

            <li className="nav__menu__link f4 text-black">
              <Link
                to="/about"
                onClick={() => this.props.appContext.setMenuActive(false)}
              >
                about
              </Link>
            </li>

            <li className="nav__menu__link f4 text-black">
              <Link
                to="/contact"
                onClick={() => this.props.appContext.setMenuActive(false)}
              >
                contact
              </Link>
            </li>

            <li className="nav__menu__link f4 text-black">
              <Link
                to="/newsletter"
                onClick={() => this.props.appContext.setMenuActive(false)}
              >
                mailing list
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const Nav = () => (
  <AppContext.Consumer>
    {appContext => <NavComponent appContext={appContext} />}
  </AppContext.Consumer>
);

export default Nav;
